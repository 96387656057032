import { IRestaurantConfiguration } from '@localina/core';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePreservedRestaurant } from '../../../../api/queries/restaurants';

interface IFeedbackMailTemplate {
    feedbackLinks: IRestaurantConfiguration['feedbackLinks'];
}

const FeedbackMailTemplate = (props: IFeedbackMailTemplate) => {
    const { i18n } = useTranslation();
    const lng = i18n.language;

    const restaurantQuery = usePreservedRestaurant();

    const restaurantName = restaurantQuery.data?.info.name;
    const locationAddress = `${restaurantQuery.data?.info.street} ${restaurantQuery.data?.info.streetNumber}`;
    const locationAddressPlace = `${restaurantQuery.data?.info.postalCode} ${restaurantQuery.data?.info.city}`;

    const reservationDate = DateTime.now().minus({ day: 1 }).set({ hour: 16, minute: 0, second: 0, millisecond: 0 });
    const reservationDateFormatted = reservationDate.setLocale(lng).toFormat('dd MMMM yyyy');
    const reservationTimeFormatted = reservationDate.setLocale(lng).toFormat('HH:mm');

    return (
        <FeedbackEmail
            locationName={restaurantName}
            locationAddress={locationAddress}
            locationAddressPlace={locationAddressPlace}
            reservationStartDate={reservationDateFormatted}
            reservationStartTime={reservationTimeFormatted}
            localchFeedbackLink={props.feedbackLinks?.localCh}
            googleFeedbackLink={props.feedbackLinks?.google}
            tripadvisorFeedbackLink={props.feedbackLinks?.tripAdvisor}
        />
    );
};

type TFeedbackEmailProps = {
    locationName?: string;
    locationAddress?: string;
    locationAddressPlace?: string;
    reservationStartDate?: string;
    reservationStartTime?: string;
    googleFeedbackLink?: string;
    localchFeedbackLink?: string;
    tripadvisorFeedbackLink?: string;
};
const FeedbackEmail = ({
    locationName,
    locationAddress,
    locationAddressPlace,
    reservationStartDate,
    reservationStartTime,
    googleFeedbackLink,
    localchFeedbackLink,
    tripadvisorFeedbackLink,
}: TFeedbackEmailProps) => {
    const { t: translate } = useTranslation();

    return (
        <table
            cellPadding="0"
            cellSpacing="0"
            style={{
                border: '1px solid #b4b4b4',
                width: '600px',
                borderCollapse: 'collapse',
                WebkitTextSizeAdjust: '100%',
                transform: 'scale(0.7)',
                marginTop: '-100px',
            }}
        >
            <tbody>
                <tr>
                    <td align="center" valign="top">
                        <table
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                                borderBottom: '1px solid #b4b4b4',
                                backgroundColor: '#b4b4b4',
                                borderCollapse: 'collapse',
                                WebkitTextSizeAdjust: '100%',
                            }}
                        ></table>
                    </td>
                </tr>
                <tr>
                    <td align="center" valign="top">
                        <table
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                                borderBottom: '1px solid #CCC',
                                backgroundColor: '#FFF',
                                borderCollapse: 'collapse',
                                WebkitTextSizeAdjust: '100%',
                            }}
                        ></table>
                    </td>
                </tr>
                <tr>
                    <td align="center" valign="top">
                        <table
                            cellPadding="0"
                            cellSpacing="0"
                            style={{
                                borderTop: '1px solid #FFF',
                                backgroundColor: '#FFF',
                                borderCollapse: 'collapse',
                                WebkitTextSizeAdjust: '100%',
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        align="left"
                                        style={{
                                            padding: '20px',
                                            textAlign: 'center',
                                            color: '#E8E8E8',
                                            lineHeight: '21px',
                                            fontFamily: 'Arial, Helvetica, sans-serif',
                                            fontSize: '14px',
                                            backgroundColor: '#709FAF',
                                        }}
                                        valign="top"
                                    >
                                        <div style={{ textAlign: 'center' }}>
                                            <span
                                                style={{
                                                    color: '#FFF',
                                                    fontSize: '28px',
                                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                                    lineHeight: '1.3',
                                                }}
                                            >
                                                {translate('integrations.items.feedback.emailPreview.title')}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        align="left"
                                        style={{
                                            padding: '20px',
                                            textAlign: 'left',
                                            color: '#000',
                                            lineHeight: '21px',
                                            fontFamily: 'Arial, Helvetica, sans-serif',
                                            fontSize: '14px',
                                            backgroundColor: '#FFF',
                                        }}
                                        valign="top"
                                    >
                                        <div>
                                            <p>
                                                {translate('integrations.items.feedback.emailPreview.greeting')}
                                                <br />
                                                <br />
                                                {translate('integrations.items.feedback.emailPreview.visitIntro')}
                                                <br />
                                                {`• ${locationName}`}
                                                <br />
                                                {`• ${locationAddress}`}
                                                <br />
                                                {`• ${locationAddressPlace}`}
                                                <br />
                                                <br />
                                                {translate(
                                                    'integrations.items.feedback.emailPreview.visitDetailsIntro',
                                                )}
                                                <br />
                                                {`• ${reservationStartDate}`}
                                                <br />
                                                {`• ${reservationStartTime}`}
                                                <br />
                                                <br />
                                                {translate('integrations.items.feedback.emailPreview.feedbackRequest')}
                                            </p>
                                            {googleFeedbackLink && (
                                                <p>
                                                    <a
                                                        href={googleFeedbackLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {translate(
                                                            'integrations.items.feedback.emailPreview.googleFeedbackLink',
                                                        )}
                                                    </a>
                                                </p>
                                            )}
                                            {localchFeedbackLink && (
                                                <p>
                                                    <a
                                                        href={localchFeedbackLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {translate(
                                                            'integrations.items.feedback.emailPreview.localChFeedbackLink',
                                                        )}
                                                    </a>
                                                </p>
                                            )}
                                            {tripadvisorFeedbackLink && (
                                                <p>
                                                    <a
                                                        href={tripadvisorFeedbackLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {translate(
                                                            'integrations.items.feedback.emailPreview.tripAdvisorFeedbackLink',
                                                        )}
                                                    </a>
                                                </p>
                                            )}
                                            <p>
                                                {translate('integrations.items.feedback.emailPreview.closing')}
                                                <br />
                                                <br />
                                                {translate('integrations.items.feedback.emailPreview.kindRegards')}
                                                <br />
                                                {locationName}
                                                <br />
                                                <br />
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default FeedbackMailTemplate;
