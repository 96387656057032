import {
    createEmptyUser,
    FormTextField,
    IUser,
    Label,
    List,
    Spinner,
    StringUtils,
    useConfirmContext,
} from '@localina/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, UserRoleMappingListItem } from '../../components';
import { useGetRestaurants } from '../../api/queries/restaurants';
import { useGetAccount } from '../../api/queries/account';
import { useCreateUser, useDeleteUser, useUpdateUser, useUser } from '../../api/queries/users';
import * as yup from 'yup';
import { Schema } from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

interface IUserViewProps {
    open: boolean;
    onClose: () => void;
    userId: string;
}

type IUserForm = Omit<IUser, 'id' | 'rolesMapping'>;

const UserView = ({ ...props }: IUserViewProps) => {
    const { t } = useTranslation();
    const { snackbar, confirm } = useConfirmContext();
    const userId: string = props.userId;
    const isEditing: boolean = userId !== 'new';
    const [user, setUser] = React.useState<IUser | undefined>(undefined);

    const createUserMutation = useCreateUser();
    const updateUserMutation = useUpdateUser();
    const deleteUserMutation = useDeleteUser();
    const accountQuery = useGetAccount();

    const userQuery = useUser(userId, { enabled: isEditing });
    const restaurantsQuery = useGetRestaurants();

    const isLoading: boolean =
        userQuery.isInitialLoading ||
        createUserMutation.isLoading ||
        updateUserMutation.isLoading ||
        deleteUserMutation.isLoading;

    const userSchema: Record<keyof IUserForm, Schema> = {
        firstName: yup.string().required().min(2).max(40),
        lastName: yup.string().required().min(2).max(40),
        email: yup
            .string()
            .test(
                'isValidEmail',
                t('core:errors.invalidEmail'),
                StringUtils.isValidEmail as yup.TestFunction<string | undefined, any>,
            )
            .required(),
    };

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(yup.object().shape(userSchema)),
        defaultValues: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
        },
    });

    const { isDirty, isValid } = methods.formState;

    React.useEffect(() => {
        setUser(userQuery.data ? userQuery.data : createEmptyUser());
    }, [userQuery.data]);

    React.useEffect(() => {
        if (props.open) {
            const propUser = userQuery.data ? userQuery.data : createEmptyUser();
            methods.reset({
                firstName: propUser.firstName,
                lastName: propUser.lastName,
                email: propUser.email,
            });
        }
    }, [props.open]);

    const displaySuccessSnackbar = () => {
        snackbar({ msg: t('user.saved'), severity: 'success' });
    };

    const displayErrorSnackbar = (deleteRequested = false) => {
        snackbar({ msg: t(`user.errors.${deleteRequested ? 'delete' : 'save'}`), severity: 'error' });
    };

    const handleSave = () => {
        if (user && methods.getValues()) {
            const updatedUser = {
                ...user,
                firstName: methods.getValues().firstName,
                lastName: methods.getValues().lastName,
                email: methods.getValues().email,
            };

            const request: IUser = {
                ...updatedUser,
                rolesMapping: (restaurantsQuery.data?.restaurants || []).map((restaurant) => {
                    return {
                        restaurantId: restaurant.id,
                        roles: updatedUser.rolesMapping.find((rm) => rm.restaurantId === restaurant.id)?.roles || [],
                    };
                }),
            };

            if (isEditing) {
                updateUserMutation.mutate(request, {
                    onError: () => {
                        displayErrorSnackbar();
                    },
                    onSuccess: displaySuccessSnackbar,
                });
            } else {
                createUserMutation.mutate(request, {
                    onError: () => {
                        displayErrorSnackbar();
                    },
                    onSuccess: displaySuccessSnackbar,
                });
            }

            setUser(updatedUser);
            props.onClose();
        }
    };

    const handleDelete = async () => {
        if (isEditing) {
            if (
                (await confirm({
                    msg: t('user.dialog.message', { name: user?.email }),
                    title: t('user.dialog.title'),
                })) === 'yes' &&
                user
            ) {
                deleteUserMutation.mutate(user, {});
            }
            props.onClose();
        }
    };

    const confirmCloseDrawer = async () => {
        if (!isDirty || (await confirm({ title: t('staffTags.form.confirmDiscardChanges.title') })) === 'yes') {
            props.onClose();
        }
    };

    return (
        <>
            <Drawer
                open={props.open}
                onClose={confirmCloseDrawer}
                title={t('user.title')}
                disabled={!isValid}
                onSave={methods.handleSubmit(handleSave)}
                onDelete={user?.id ? handleDelete : undefined}
            >
                {user && (
                    <FormProvider {...methods}>
                        <form>
                            <FormTextField name={'firstName'} label={t('user.fields.firstName')} />
                            <FormTextField name={'lastName'} label={t('user.fields.lastName')} />
                            <FormTextField name={'email'} label={t('user.fields.email')} />
                            <div className="user__subheader">
                                <Label type="title" variant="h6" value={t('user.restaurants')} />
                            </div>
                            <List>
                                {restaurantsQuery.data?.restaurants
                                    .filter(
                                        (restaurant) =>
                                            accountQuery.isSuccess &&
                                            accountQuery.data.hasFeature('users', restaurant.id),
                                    )
                                    ?.map((restaurant) => (
                                        <UserRoleMappingListItem
                                            key={restaurant.id}
                                            user={user}
                                            restaurant={restaurant}
                                            onChange={setUser}
                                        />
                                    ))}
                            </List>
                        </form>
                    </FormProvider>
                )}
            </Drawer>
            {isLoading && <Spinner />}
        </>
    );
};

export default UserView;
