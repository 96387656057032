import { List, ListItem } from '@localina/core';
import { RestaurantLogoIcon } from '@localina/icons';
import { Feedback, Hub, Language, MenuBook } from '@mui/icons-material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRestaurant } from '../../api/queries/restaurants';
import { Page, PlatformsIntegrationView, RestaurantLogoIntegrationView, WidgetIntegrationView } from '../../components';
import { Path } from '../../enums';
import { IPathParams, PathUtils } from '../../utils';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { compact } from 'lodash';
import { FeedbackIntegrationView } from '../../components/IntegrationViews/FeedbackIntegration/FeedbackIntegrationView';

export enum IntegrationItem {
    WIDGET = 'widget',
    LOGO = 'logo',
    PLATFORMS = 'platforms',
    // QRCODE = 'qrcode',
    MENU = 'menu',
    FEEDBACK = 'feedback',
}

type TIntegrationMenuPathParams = Pick<IPathParams, 'integrationId' | 'restaurantId'>;

const Integrations: React.FC = () => {
    const { t } = useTranslation();
    const params = useParams() as unknown as TIntegrationMenuPathParams;
    const navigate = useNavigate();

    const restaurantQuery = useRestaurant();

    const handleItemClick = (item: IntegrationItem) => () => {
        navigate(
            PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_INTEGRATION, {
                restaurantId: params.restaurantId,
                integrationId: item,
            }),
        );
    };

    const handleCloseDrawer = useCallback(() => {
        navigate(
            PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_INTEGRATIONS, {
                restaurantId: params.restaurantId,
            }),
        );
    }, [params.restaurantId]);

    const getIconForIntegrationItem = (item: IntegrationItem) => {
        switch (item) {
            case IntegrationItem.WIDGET:
                return <Language />;
            case IntegrationItem.LOGO:
                return <RestaurantLogoIcon />;
            case IntegrationItem.PLATFORMS:
                return <Hub />;
            case IntegrationItem.MENU:
                return <MenuBook />;
            case IntegrationItem.FEEDBACK:
                return <Feedback />;
            //case IntegrationItem.QRCODE:
            //    return <RestaurantQrCodeIcon />;
        }
    };

    useEffect(() => {
        if (params.integrationId && !Object.values(IntegrationItem).includes(params.integrationId)) {
            handleCloseDrawer();
        }
    }, [params.integrationId]);

    const [canUseMenu, canUseLogo, canUseFeedback] = useHaveAccountFeatures(['menu', 'restaurantLogo', 'feedback']);

    return (
        <Page name="integrations" title={t('integrations.title')} isLoading={restaurantQuery.isInitialLoading}>
            <List>
                {compact([
                    IntegrationItem.WIDGET,
                    canUseLogo && IntegrationItem.LOGO,
                    IntegrationItem.PLATFORMS,
                    canUseMenu && IntegrationItem.MENU,
                    canUseFeedback && IntegrationItem.FEEDBACK,
                    //IntegrationItem.QRCODE,
                ]).map((item) => (
                    <ListItem
                        key={item}
                        leftIcon={getIconForIntegrationItem(item)}
                        title={t(`integrations.items.${item}.title`)}
                        subtitle={t(`integrations.items.${item}.subtitle`)}
                        onClick={handleItemClick(item)}
                    />
                ))}
            </List>
            {restaurantQuery.data && (
                <>
                    <WidgetIntegrationView
                        isOpen={params.integrationId === IntegrationItem.WIDGET}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                    />
                    <RestaurantLogoIntegrationView
                        isOpen={params.integrationId === IntegrationItem.LOGO && canUseLogo}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                    />
                    <PlatformsIntegrationView
                        isOpen={params.integrationId === IntegrationItem.PLATFORMS}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                    />
                    <FeedbackIntegrationView
                        isOpen={params.integrationId === IntegrationItem.FEEDBACK && canUseFeedback}
                        onClose={handleCloseDrawer}
                        restaurant={restaurantQuery.data}
                    />
                </>
            )}
        </Page>
    );
};

export default Integrations;
