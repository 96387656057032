var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ListSubheader, MenuItem, TextField as MuiTextField } from '@mui/material';
import { InputField } from '../InputField';
export var SelectField = function (props) {
    var handleChange = function (event) {
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    };
    return (_jsx(InputField, __assign({ name: "localina-selectfield", icon: props.icon, helperText: props.options.length === 0 ? props.emptyMessage : undefined }, { children: _jsx(MuiTextField, __assign({ variant: "outlined", label: props.label, size: props.size, value: props.value || '', fullWidth: true, select: true, disabled: props.disabled, required: props.required, margin: props.margin, SelectProps: __assign({ multiple: props.multiple, value: props.value, IconComponent: props.arrowIcon, MenuProps: { PaperProps: { style: { maxHeight: '50%' } } } }, props.SelectProps), onChange: handleChange }, { children: props.options
                .filter(function (option) {
                return props.renderHeaders || typeof option === 'string' || !option.isHeader;
            })
                .map(function (option, index) {
                var label = typeof option === 'string' ? option : option.label;
                var value = typeof option === 'string' ? option : option.value;
                var disabled = typeof option === 'string' ? undefined : option.disabled;
                var isHeader = typeof option === 'string' ? false : option.isHeader;
                var severity = typeof option === 'string' ? '' : option.severity || '';
                return isHeader ? (_jsx(ListSubheader, { children: label }, index)) : (_jsx(MenuItem, __assign({ sx: { color: severity === 'error' ? '#821E3E' : undefined }, value: value, disabled: disabled }, { children: label }), value));
            }) })) })));
};
