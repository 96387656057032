import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRestaurant, Label, Spinner, Switch, TextField, useConfirmContext } from '@localina/core';
import { Drawer } from '../../Drawer';
import { TUpdateFeedbackPayload, useUpdateFeedbackMutation } from '../../../api/queries/feedback';
import { Divider } from '@mui/material';
import FeedbackMailTemplate from './FeedbackMailTemplate/FeedbackMailTemplate';

interface IProps {
    isOpen: boolean;
    restaurant: IRestaurant;
    onClose: () => void;
}

export const FeedbackIntegrationView: React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const { snackbar, alert } = useConfirmContext();
    const updateFeedbackMutation = useUpdateFeedbackMutation();

    const [feedbackLinks, setFeedbackLinks] = useState<TUpdateFeedbackPayload['feedbackLinks']>({
        localCh: props.restaurant.configuration.feedbackLinks?.localCh || '',
        google: props.restaurant.configuration.feedbackLinks?.google || '',
        tripAdvisor: props.restaurant.configuration.feedbackLinks?.tripAdvisor || '',
    });

    const setFeedbackLink = (name: keyof TUpdateFeedbackPayload['feedbackLinks']) => (value: string) => {
        setFeedbackLinks((prevState) => ({ ...prevState, [name]: value }));
    };

    const [activateFeedbackMail, setActivateFeedbackMail] = useState(props.restaurant.configuration.feedbackActivated);

    const toggleActivateFeedbackMail = (value: boolean) => {
        if (value) {
            alert({ msg: t('integrations.items.feedback.fields.activateFeedbackMailTooltip') });
        }
        setActivateFeedbackMail(value);
    };

    const updateFeedback = () => {
        updateFeedbackMutation.mutate(
            {
                restaurantId: props.restaurant.id,
                payload: {
                    feedbackActivated: activateFeedbackMail,
                    feedbackLinks,
                },
            },
            {
                onSuccess: () => {
                    snackbar({
                        msg: t('integrations.items.feedback.successfully-saved'),
                        severity: 'success',
                        autoHideDuration: 2000,
                    });
                    props.onClose();
                },
                onError: () => {
                    snackbar({ msg: t('integrations.items.feedback.error'), severity: 'error' });
                },
            },
        );
    };

    useEffect(() => {
        setActivateFeedbackMail(props.restaurant.configuration.feedbackActivated);
    }, [props.restaurant.configuration.feedbackActivated]);
    useEffect(() => {
        setFeedbackLinks({
            localCh: props.restaurant.configuration.feedbackLinks?.localCh || '',
            google: props.restaurant.configuration.feedbackLinks?.google || '',
            tripAdvisor: props.restaurant.configuration.feedbackLinks?.tripAdvisor || '',
        });
    }, [props.restaurant.configuration.feedbackLinks]);

    return (
        <Drawer
            className="feedback-view"
            open={props.isOpen}
            onClose={props.onClose}
            title={t('integrations.items.feedback.title')}
            onSave={updateFeedback}
        >
            <div className="integration feedback">
                <div className="group">
                    <Switch
                        label={t('integrations.items.feedback.fields.activateFeedbackMailSwitch')}
                        checked={activateFeedbackMail}
                        onChange={toggleActivateFeedbackMail}
                        tooltip={t('integrations.items.feedback.fields.activateFeedbackMailTooltip')}
                    />
                </div>
                <div className="group">
                    <Label type={'title'} value={t('integrations.items.feedback.fields.google')} variant={'h5'} />
                    <TextField
                        label={t('integrations.items.feedback.fields.link')}
                        name="googleLink"
                        multiline
                        minRows={5}
                        value={feedbackLinks.google}
                        onChange={setFeedbackLink('google')}
                    />
                </div>
                <div className="group">
                    <Label type={'title'} value={t('integrations.items.feedback.fields.tripadvisor')} variant={'h5'} />
                    <TextField
                        label={t('integrations.items.feedback.fields.link')}
                        name="tripadvisorLink"
                        multiline
                        minRows={5}
                        value={feedbackLinks.tripAdvisor}
                        onChange={setFeedbackLink('tripAdvisor')}
                    />
                </div>
                {/*<div className="group">*/}
                {/*    <Label type={'title'} value={t('integrations.items.feedback.fields.localCh')} variant={'h5'} />*/}
                {/*    <TextField*/}
                {/*        label={t('integrations.items.feedback.fields.link')}*/}
                {/*        name="localCh"*/}
                {/*        multiline*/}
                {/*        minRows={5}*/}
                {/*        value={feedbackLinks.localCh}*/}
                {/*        onChange={setFeedbackLink('localCh')}*/}
                {/*    />*/}
                {/*</div>*/}
                <Divider className="title-divider" />
                <div className="group" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Label type={'title'} value={t('integrations.items.feedback.preview')} variant={'h5'} />
                    <FeedbackMailTemplate feedbackLinks={feedbackLinks} />
                </div>
            </div>
            {updateFeedbackMutation.isLoading && <Spinner />}
        </Drawer>
    );
};
