import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { ILocalizedError } from '@localina/core';
import { LocalinaApiContext } from '../../../index';
import { queryKeys } from './query-keys';

type TUpdateFeedbackPayload = {
    feedbackActivated: boolean;
    feedbackLinks: {
        google: string;
        localCh: string;
        tripAdvisor: string;
    };
};

const useUpdateFeedbackMutation = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            restaurantId: string;
            payload: TUpdateFeedbackPayload;
        }
    >,
) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (variables) =>
            LocalinaApiContext.serviceApi.updateFeedback(variables.restaurantId, variables.payload),
        onSuccess: () => queryClient.invalidateQueries(queryKeys.restaurants.all),
        ...options,
    });
};

export { useUpdateFeedbackMutation };
export type { TUpdateFeedbackPayload };
