import {
    DATE_FORMAT,
    DAY_SHORT_DATE_FORMAT,
    RichTextEditor,
    SERVER_DATE_FORMAT,
    Spinner,
    Tabs,
    useConfirmContext,
} from '@localina/core';
import { Content } from '@tiptap/core';
import { DateTime } from 'luxon';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { MENU_DAYS_COUNT, useRestaurantMenus, useUpdateMenuContent } from '../../../api/queries/restaurantMenu';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from '../../../api/queries/restaurants';
import { Paper } from '@mui/material';

type TMenuEditorProps = {
    contentNotSaved: boolean;
    setContentNotSaved: Dispatch<SetStateAction<boolean>>;
};

const MenuEditor = (props: TMenuEditorProps) => {
    const today = DateTime.now().toFormat(DATE_FORMAT);
    const { t, i18n } = useTranslation();

    const { snackbar, confirm } = useConfirmContext();
    const restaurantQuery = useRestaurant();
    const restaurantMenusQuery = useRestaurantMenus();
    const updateMenuMutation = useUpdateMenuContent();

    const findMenuByDate = useCallback(
        (targetDate: string) => {
            return restaurantMenusQuery.data?.pages.length
                ? restaurantMenusQuery.data.pages[0].items.find(({ date }) => targetDate === date)
                : undefined;
        },
        [restaurantMenusQuery.data?.pages],
    );

    const tabs = useMemo(() => {
        const arrayOfTabs = [];
        const todayDateTime = DateTime.fromFormat(today, DATE_FORMAT);
        let dateTime;
        for (let day = 0; day < MENU_DAYS_COUNT; day++) {
            dateTime = todayDateTime.plus({ day });
            arrayOfTabs.push({
                label: dateTime.toFormat(DAY_SHORT_DATE_FORMAT, { locale: i18n.language }),
                value: dateTime.toFormat(SERVER_DATE_FORMAT),
                className: findMenuByDate(dateTime.toFormat(SERVER_DATE_FORMAT)) ? 'filled' : 'blank',
            });
        }
        return arrayOfTabs;
    }, [today, restaurantMenusQuery.data]);

    const [activeTab, setActiveTab] = useState(tabs[0].value);

    const handleChangeTab = async (newTab: string) => {
        if (
            !props.contentNotSaved ||
            (await confirm({
                title: t('integrations.items.menu.infoMessages.unsavedChangesTitle'),
                msg: t('integrations.items.menu.infoMessages.unsavedChangesMessage'),
            })) === 'no'
        ) {
            setActiveTab(newTab);
        }
    };

    const content: Content | undefined = useMemo(() => {
        const menuObject = findMenuByDate(activeTab);
        return menuObject?.content ? JSON.parse(menuObject.content) : undefined;
    }, [activeTab, restaurantMenusQuery.data]);

    const handleSave = (newContent: Content | null) => {
        updateMenuMutation.mutate(
            {
                menu: [{ date: activeTab, content: newContent ? JSON.stringify(newContent) : '' }],
            },
            {
                onSuccess: () => {
                    snackbar({ msg: t('integrations.items.menu.infoMessages.savedMenuSuccess'), severity: 'success' });
                },
                onError: () => {
                    snackbar({ msg: t('integrations.items.menu.infoMessages.errorMessage'), severity: 'error' });
                },
            },
        );
    };

    const isLoading = updateMenuMutation.isLoading || restaurantMenusQuery.isInitialLoading;

    return (
        <div className={'menu-editor-container'}>
            <Tabs tabs={tabs} onChange={handleChangeTab} active={activeTab} className={'days-tabs'} />
            <Paper
                sx={{
                    boxShadow: 'none',
                    height: '100%',
                    '.editor-content': {
                        backgroundColor: restaurantQuery.data?.configuration.menuConfig?.backgroundColor
                            ? `${String(restaurantQuery.data?.configuration.menuConfig.backgroundColor)}`
                            : 'white',
                    },
                }}
            >
                <RichTextEditor
                    content={content}
                    contentRelatedString={activeTab}
                    onSave={handleSave}
                    setContentNotSaved={props.setContentNotSaved}
                />
            </Paper>
            {isLoading && <Spinner />}
        </div>
    );
};

export default MenuEditor;
