import { IGuestSearchFields, ISort } from '@localina/core';
import { ExportFormat, GuestsExportFormat } from '../enums';

function relativeUrl(relative: string): URL {
    return new URL(`/api/v1${relative}`, window.location.origin);
}

export const ServiceApiConstants = {
    getAccount(): URL {
        return relativeUrl('/accounts');
    },

    getRestaurants(): URL {
        return relativeUrl('/restaurants');
    },

    updateRestaurant(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}`);
    },

    createRestaurant(): URL {
        return relativeUrl('/restaurants/');
    },

    getRestaurantAvailableTimes(
        restaurantId: string,
        participants: number,
        datetime: string,
        expectedOccupancyTime?: number,
    ): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/slots`);
        const queryParams = new URLSearchParams();
        queryParams.append('participants', `${participants}`);
        queryParams.append('shiftDateTime', datetime);
        if (expectedOccupancyTime) {
            queryParams.append('expectedOccupancyTime', `${expectedOccupancyTime}`);
        }
        url.search = queryParams.toString();
        return url;
    },

    getRestaurantAvailableOccupancyTimes(
        restaurantId: string,
        participants: number,
        reservationDateTime: string,
        shiftId: string,
        areaId: string,
    ): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/occupancy-times`);
        const queryParams = new URLSearchParams();
        queryParams.append('participants', `${participants}`);
        queryParams.append('reservationDateTime', reservationDateTime);
        queryParams.append('shiftId', shiftId);
        queryParams.append('areaId', areaId);
        url.search = queryParams.toString();
        return url;
    },

    createRestaurantShift(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/shifts`);
    },

    updateRestaurantShift(restaurantId: string, shiftId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/shifts/${shiftId}`);
    },

    createRestaurantClosingDay(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/closing-days`);
    },

    updateRestaurantClosingDay(restaurantId: string, closingDayId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/closing-days/${closingDayId}`);
    },

    createRestaurantSpecialOpeningHour(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/special-opening-hours`);
    },

    updateRestaurantSpecialOpeningHour(restaurantId: string, specialOpeningHourId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/special-opening-hours/${specialOpeningHourId}`);
    },

    getActivePlatforms(): URL {
        return relativeUrl('/configuration/platforms');
    },

    updateRestaurantPlatforms(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/platforms`);
    },

    getRestaurantStats(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/stats`);
    },

    getReservations(restaurantId: string, date: string): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/reservations`);
        const queryParams = new URLSearchParams();
        queryParams.append('date', date);
        url.search = queryParams.toString();
        return url;
    },

    getReservation(restaurantId: string, reservationId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/${reservationId}`);
    },

    getPublicReservationByToken(restaurantId: string, token: string): URL {
        const encodedToken = encodeURIComponent(token);
        return new URL(
            `/api/v1/public/restaurants/${restaurantId}/review-reservation/${encodedToken}`,
            window.location.origin,
        );
    },

    createReservation(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations`);
    },

    createWalkinReservation(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/walkins`);
    },

    updateReservationStatus(restaurantId: string, reservationId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/${reservationId}/change-status`);
    },
    updateReservationStatusByToken(restaurantId: string, reservationId: string, token: string): URL {
        return relativeUrl(`/public/restaurants/${restaurantId}/reservations/${reservationId}/${token}/change-status`);
    },

    updateReservationTableIds(restaurantId: string, reservationId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/${reservationId}/table-assignments`);
    },

    updateWalkinTableIds(restaurantId: string, walkinId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/walkins/${walkinId}/table-assignments`);
    },

    updateWalkinReservation(restaurantId: string, reservationId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/walkins/${reservationId}`);
    },

    getUnreadReservations(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/unread`);
    },

    markReservationAsRead(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/mark-read`);
    },

    getReservationsImportPreview(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/dry-import`);
    },
    getValidatedReservationsExport(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/dry-import/download`);
    },
    getReservationsImport(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/import`);
    },

    getGuestList(
        restaurantId: string,
        page: number,
        limit: number,
        sort?: ISort,
        query?: string,
        field?: IGuestSearchFields,
    ): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/guests`);
        const queryParams = new URLSearchParams();
        queryParams.append('page', `${page}`);
        if (sort) {
            queryParams.append('sort', `${sort.by}`);
            queryParams.append('order', `${sort.order}`);
        }
        queryParams.append('limit', `${limit}`);
        if (query && query.trim().length !== 0) {
            queryParams.append('q', `${query}`);
        }
        if (field) {
            queryParams.append('searchField', field);
        }
        url.search = queryParams.toString();
        return url;
    },

    getGuestsExport(restaurantId: string, format: GuestsExportFormat): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/guests/export`);
        const queryParams = new URLSearchParams();
        queryParams.append('format', format);
        url.search = queryParams.toString();
        return url;
    },

    getGuestsImportPreview(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/guests/dry-import`);
    },

    getGuestsImport(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/guests/import`);
    },

    getImportMetadata(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/import-metadata`);
    },

    getGuest(restaurantId: string, guestId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/guests/${guestId}`);
    },

    getGuestReservationList(restaurantId: string, guestId: string, page: number, limit: number): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/guests/${guestId}/reservations`);
        const queryParams = new URLSearchParams();
        queryParams.append('page', `${page}`);
        queryParams.append('limit', `${limit}`);
        url.search = queryParams.toString();
        return url;
    },

    getMessageList(restaurantId: string, page: number, limit: number): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/messages`);
        const queryParams = new URLSearchParams();
        queryParams.append('page', `${page}`);
        queryParams.append('limit', `${limit}`);
        url.search = queryParams.toString();
        return url;
    },

    createMessage(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/messages`);
    },

    getMessage(restaurantId: string, messageId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/messages/${messageId}`);
    },

    getRestuarantLogo(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/logo`);
    },
    getRestaurantDocument(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/document`);
    },

    getUsers(): URL {
        return relativeUrl('/users');
    },

    getUser(userId: string): URL {
        return relativeUrl(`/users/${userId}`);
    },

    saveRestaurantArea(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/areas`);
    },

    editRestaurantArea(restaurantId: string, areaId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/areas/${areaId}`);
    },

    deleteRestaurantArea(restaurantId: string, areaId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/areas/${areaId}`);
    },
    editRestaurantVirtualArea(restaurantId: string, virtualAreaId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/virtual-areas/${virtualAreaId}`);
    },

    getPardot(): string {
        return 'https://info.mylocalina.ch/l/415082/2022-11-04/21ywgw4"';
    },

    getAreaTimeSlot(
        restaurantId: string,
        participants: number,
        reservationDateTime: string,
        shiftId: string,
        areaId: string,
        expectedOccupancyTime?: number,
    ): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/slots`);
        const queryParams = new URLSearchParams();
        queryParams.append('participants', `${participants}`);
        queryParams.append('reservationDateTime', reservationDateTime);
        queryParams.append('shiftId', shiftId);
        queryParams.append('areaId', areaId);
        if (expectedOccupancyTime) {
            queryParams.append('expectedOccupancyTime', `${expectedOccupancyTime}`);
        }
        url.search = queryParams.toString();
        return url;
    },

    saveTablePlan(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/table-plans`);
    },

    updateTablePlan(restaurantId: string, tablePlanId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/table-plans/${tablePlanId}`);
    },

    saveTablePlanDesign(restaurantId: string, tablePlanId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/table-plans/${tablePlanId}/table-plan-design`);
    },

    deleteTableFromTableEditor(restaurantId: string, tablePlanId: string, tableId: string): URL {
        return relativeUrl(
            `/restaurants/${restaurantId}/table-plans/${tablePlanId}/table-plan-design/tables/${tableId}`,
        );
    },

    deleteTablePlan(restaurantId: string, tablePlanId: string) {
        return relativeUrl(`/restaurants/${restaurantId}/table-plans/${tablePlanId}`);
    },

    getReport(
        restaurantId: string,
        shiftIds: string[],
        areaId: string,
        dateFrom: string,
        dateTo: string,
        creationTime: boolean,
    ): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/reports`);
        const queryParams = new URLSearchParams();
        queryParams.append('shiftIds', `${shiftIds.join(',')}`);
        queryParams.append('areaId', areaId);
        queryParams.append('dateFrom', dateFrom);
        queryParams.append('dateTo', dateTo);
        queryParams.append('creationTime', creationTime.toString());
        url.search = queryParams.toString();
        return url;
    },
    restaurantDailyNote(restaurantId: string, date: string): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/daily-note`);
        const queryParams = new URLSearchParams();
        queryParams.append('date', date);
        url.search = queryParams.toString();
        return url;
    },
    restaurantMenuConfiguration(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/menu-config`);
    },
    createAndUpdateRestaurantMenus(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/menus`);
    },
    restaurantMenu(restaurantId: string, dateFrom: string, dateTo: string): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/menus`);
        const queryParams = new URLSearchParams();
        queryParams.append('dateFrom', dateFrom);
        queryParams.append('dateTo', dateTo);
        url.search = queryParams.toString();
        return url;
    },
    createRestaurantStaffTag(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/staff-tags`);
    },
    updateRestaurantStaffTag(restaurantId: string, staffTagId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/staff-tags/${staffTagId}`);
    },
    updateFeedback(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/feedback`);
    },
    deleteRestaurantStaffTag(restaurantId: string, staffTagId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/staff-tags/${staffTagId}`);
    },

    getReportExport(
        restaurantId: string,
        shiftIds: string[],
        areaId: string,
        dateFrom: string,
        dateTo: string,
        creationTime: boolean,
        format: ExportFormat,
    ): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/reports/export`);
        const queryParams = new URLSearchParams();
        queryParams.append('shiftIds', `${shiftIds.join(',')}`);
        queryParams.append('areaId', areaId);
        queryParams.append('dateFrom', dateFrom);
        queryParams.append('dateTo', dateTo);
        queryParams.append('creationTime', creationTime.toString());
        queryParams.append('format', format);
        url.search = queryParams.toString();
        return url;
    },

    getDailyReportExport(restaurantId: string, date: string): URL {
        const url = relativeUrl(`/restaurants/${restaurantId}/reservations/daily-export`);
        const queryParams = new URLSearchParams();
        queryParams.append('date', date);
        url.search = queryParams.toString();
        return url;
    },

    getExportReservations(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/reservations/export`);
    },

    saveButtonTheme(restaurantId: string): URL {
        return relativeUrl(`/restaurants/${restaurantId}/button-theme`);
    },
};
